import React from 'react';
import { JsonExplorer, List, ListItem, ModalControl } from 'components';
import { OrderChangeLog } from 'interfaces/api';
import { ApiRequest, InfiniteScroll, RequestResultComponentProps } from 'containers';
import { FormatDate, PaginationResponse, Translate, useApi, useIntl } from 'providers';
import { OrderControlContext } from 'modules/orders/index';
import messages from 'messages';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons';
import { SortGrouping } from 'interfaces';

const labels = messages.orders.controls.changeLog;

export const ChangeLog: React.FC<OrderControlContext> = (props) => {

  const { data: { id } } = props;
  const { orders: { getOrderChangeLog } } = useApi();

  const { translate } = useIntl();

  const toListItem = (log: OrderChangeLog): ListItem => {

    return {
      id: log.id,
      title: <span dangerouslySetInnerHTML={{ __html: translate(messages.orders.controls.changeLog.actions[log.action] || log.action, log.data) }}/>,
      subtitle: log.info || undefined,
      fields: [{
        label: <FormatDate date={log.created_at} options={{ withSeconds: true }}/>,
        value: log.user_name || (<Translate message={messages.orders.controls.changeLog.unknownUser}/>),
        flipped: true,
      }],
      meta: log.data ? <JsonExplorer json={log.data}/> : undefined,
      groupByValue: log.created_at,
    };
  };

  return (
    <ModalControl
      icon={faFileAlt}
      label={labels.label}
      modal={{
        scrollY: true,
        footer: null,
        children: (
          <ApiRequest
            request={(offset: number) => getOrderChangeLog({ offset, pool_id: id })}
            children={({ data, next }: RequestResultComponentProps<PaginationResponse<OrderChangeLog>>) => (
              <InfiniteScroll onNext={next}>
                <List
                  items={data.results.map(toListItem)}
                  groupBy={SortGrouping.DATE}
                  suppressCollapseAccordion
                  itemIsCollapse={item => item.meta ? <pre style={{ whiteSpace: 'normal' }}>{item.meta}</pre> : null}
                />
              </InfiniteScroll>
            )}
          />
        ),
      }}
    />
  );

};
