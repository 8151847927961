import React from 'react';
import { Color, CssColor, isI18nMessage, Message } from 'interfaces';
import messages from 'messages';
import { Translate, useTranslate } from 'providers';
import { Checkbox, DatePicker, DatePickerProps, Flag, Input, Select } from 'components';
import styles from './Filters.module.less';
import cx from 'classnames';
import { filter, isBoolean, kebabCase, keys, nth } from 'lodash';
import { GuardConfig, useGuard } from 'containers';

export type FilterItem = {
  value: any;
  label: Message;
  flag?: Color | CssColor | (Color | CssColor)[];
  guard?: GuardConfig;
};

export type Filter = {
  label?: Message;
  placeholder?: Message;
  filter: (...value: any[]) => any;
  items?: FilterItem[];
  multi?: boolean;
  isText?: boolean;
  isDate?: DatePickerProps | boolean;
  disallowClear?: boolean;
  disabled?: boolean;
  guard?: GuardConfig;
};

type Props = {
  onFilter: (value: any) => void;
  filterValues: any;
  filters?: Filter[];
  className?: string;
  isHorizontal?: boolean;
};

const getKey = (message: Message) => {
  if (!message) {
    return undefined;
  }
  return typeof message === 'string' ? kebabCase(message) : (isI18nMessage(message) ? message.id : message + '');
};

export const Filters = (props: Props) => {

  const { onFilter, filters, filterValues } = props;
  const translate = useTranslate();
  const guard = useGuard();

  return (
    <div className={cx(styles.container, props.className, { [styles.horizontal]: props.isHorizontal })}>
      {filter(filters || []).map((filter, index) => {

        const { disabled, placeholder } = filter;

        if (filter.guard && !guard(filter.guard, () => true)) {
          return null;
        }

        const key = nth(keys(filter.filter()), 0);
        const value = key ? filterValues?.[key] : undefined;

        const dateProps = filter.isDate ? (isBoolean(filter.isDate) ? {} : filter.isDate) : undefined;

        const translatedPlaceholder = placeholder ? translate(placeholder) : translate(messages.general.filter.placeholder, { title: translate(filter.label) });

        if (filter.items) {

          const items = filter.items.filter(i => i.guard ? guard(i.guard, () => i) : i);

          if (filter.multi) {
            return items.map(item => (
              <Checkbox
                key={getKey(item.label)}
                disabled={disabled}
                onChange={checked => onFilter(filter.filter(checked ? true : undefined))}
              >
                <Translate message={item.label}/>
              </Checkbox>
            ));
          } else {
            return (
              <React.Fragment key={index}>
                <h3>
                  <Translate message={filter.label}/>
                </h3>
                <Select
                  disabled={disabled}
                  placeholder={translatedPlaceholder}
                  onChange={(v: any) => onFilter(filter.filter(v))}
                  allowClear={!filter.disallowClear}
                  value={value !== undefined ? value + '' : undefined}
                  popupMatchSelectWidth={false}
                  options={items.map(item => ({
                    value: item.value + '',
                    children: (
                      <>
                        {item.flag && <Flag colors={item.flag}/>}
                        {translate(item.label)}
                      </>
                    ),
                  }))}
                />
              </React.Fragment>
            );
          }
        } else if (filter.isText) {
          return <Input key={index} disabled={disabled}/>;
        } else if (dateProps) {
          const convertFormat = (dateProps as any).showTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
          return (
            <React.Fragment key={index}>
              <h3>
                <Translate message={filter.label}/>
              </h3>
              <DatePicker
                disabled={disabled}
                {...dateProps}
                onChange={v => onFilter(filter.filter(v ? v.format(convertFormat) : undefined))}
                placeholder={translatedPlaceholder}
                format={'L' + ((dateProps as any).showTime ? ' - HH:mm' : '')}
              />
            </React.Fragment>
          );
        }

        return null;
      })}
    </div>
  );
};
