import React from 'react';
import { Container, ControlProps, ModalFormControl, Tabs } from 'components';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { useApi, useIntl } from 'providers';
import { FormLayout, useGuard } from 'containers';
import { AggregatedProfileProperties, AggregatedProfileType, Feature, OrderReason, OrderWizardLocalization } from 'interfaces/api';
import { AnyFunction, splitEnumOptions } from 'utils/helpers';
import { useOrdersConfig } from 'modules/orders/providers';
import { DiagnosesFormItem } from 'modules/orders/components';
import { flatMap, map, omit, set, values } from 'lodash';
import { groupProfilesByCostUnit } from 'modules/orders/utils.tsx';

type Props = ControlProps & {
  aid: number;
  profiles: AggregatedProfileProperties[];
  onSuccess?: AnyFunction;
  localisation: OrderWizardLocalization;
};

const labels = messages.orders.wizard.profiles;
const additionalLabels = messages.orders.additionalFields;

export const EditProfilesControl = (props: Props) => {

  const { aid, profiles, localisation, onSuccess, ...controlProps } = props;

  const { profiles: { batchUpdateProfiles } } = useApi();
  const { preferences } = useOrdersConfig();

  const guard = useGuard();

  const { translate } = useIntl();

  const hasRequirementDiagnosesFeature = guard({ feature: Feature.RequirementDiagnoses }, () => true);

  const profileGroups = groupProfilesByCostUnit(profiles, hasRequirementDiagnosesFeature, translate(labels.labels.pool));

  return (
    <ModalFormControl
      label={labels.edit}
      icon={faEdit}
      {...controlProps}
      modal={{
        narrow: true,
        okText: messages.general.save,
        title: labels.edit,
      }}
      form={{
        onSuccess,
        initialValue: {
          aid,
          profileGroups,
        },
        request: (v) => {
          const profiles = flatMap(values(v.profileGroups), g => map(g.profiles, p => omit(p, 'type')));
          batchUpdateProfiles({ aid, profiles });
        },
        children: ({ DynamicFields, value, onChange }) => (
          <Tabs
            tabs={map(values(profileGroups), g => ({
              title: g.name,
              children: (
                <Container scrollY>
                  <FormLayout embedded>
                    <DynamicFields
                      sortable
                      hideAdd
                      value={g.profiles}
                      collapse={{ accordion: true, title: v => v.name }}
                      itemReadOnly={index => g.profiles[index].type !== AggregatedProfileType.Personal}
                      itemSortable={index => g.profiles[index].type === AggregatedProfileType.Personal}
                      onChange={(v) => {
                        const profiles = v.slice();
                        const data = { ...value };
                        set(data, `profileGroups.${g.name}.profiles`, profiles);
                        onChange(data);
                      }}
                    >
                      {({ Input, Radio, index }) => {
                        const onProfilePropertyChanged = (property: string, val: any) => {
                          const profiles = g.profiles.slice();
                          // @ts-expect-error todo
                          profiles[index][property] = val;
                          const data = { ...value };
                          set(data, `profileGroups.${g.name}.profiles`, profiles);
                          onChange(data);
                        };

                        return (
                          <FormLayout embedded>
                            <Input
                              disabled={g.profiles[index].type !== AggregatedProfileType.Personal}
                              property={'name'}
                              label={labels.name}
                              onChange={v => onProfilePropertyChanged('name', v)}
                            />
                            {preferences.orderWizardProfilesSetDiagnoseText && (
                              <Input
                                disabled={g.profiles[index].type !== AggregatedProfileType.Personal}
                                property={'diagnosis'}
                                label={messages.general.diagnosis}
                                onChange={v => onProfilePropertyChanged('diagnosis', v)}
                              />
                            )}
                            {preferences.orderWizardProfilesSetFreeText && (
                              <Input
                                disabled={g.profiles[index].type !== AggregatedProfileType.Personal}
                                property={'freeText'}
                                label={messages.orders.additionalFields.freeText}
                                onChange={v => onProfilePropertyChanged('freeText', v)}
                              />
                            )}
                            {g.profiles[index].type === AggregatedProfileType.Personal && (
                              <DiagnosesFormItem
                                costUnit={g.profiles[index].costUnit}
                                value={g.profiles[index]}
                                onChange={v => onProfilePropertyChanged('selectedDiagnoses', v.selectedDiagnoses)}
                              />
                            )}
                            {localisation === OrderWizardLocalization.GER && !preferences.orderWizardHideAdditionalInfo && (
                              <Radio
                                disabled={g.profiles[index].type !== AggregatedProfileType.Personal}
                                property={'orderReason'}
                                values={splitEnumOptions(OrderReason, additionalLabels.orderReasons)}
                                optionType="button"
                                buttonStyle="solid"
                                onChange={v => onProfilePropertyChanged('orderReason', v)}
                              />
                            )}
                          </FormLayout>
                        );
                      }}
                    </DynamicFields>
                  </FormLayout>
                </Container>
              ),
            }))}
          />
        ),
      }}
    />
  );
};
