import React, { useEffect, useMemo } from 'react';
import { TranslateFunc, useTranslateFunction } from './useTranslateFunction.tsx';
import { FormatDateFunc, useFormatDateFunction } from './useFormatDateFunction.tsx';
import { filter, first } from 'lodash';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/it';
import 'dayjs/locale/fr';
import 'dayjs/locale/hu';
import 'dayjs/locale/nl';
import { FormatPriceFunc, useFormatPriceFunction } from 'providers/IntlProvider/useFormatPriceFunction.tsx';
import { FormatPriceDecimalType, LanguageCode } from 'interfaces/api';
import { create } from 'zustand';
import messages from 'messages';
import { useEnvStore } from 'providers/EnvProvider';
import { createSelectors } from 'utils/helpers.ts';
import { devtools } from 'zustand/middleware';

export interface IntlState {
  locale: LanguageCode;
  setLocale: (locale: LanguageCode) => void;
  translations: Record<string, string>;
  setTranslations: (translations: Record<string, string>) => void;
  translate: TranslateFunc;
  formatDate: FormatDateFunc;
  formatPrice: FormatPriceFunc;
  decimalType: FormatPriceDecimalType;
  setDecimalType: (decimalType: FormatPriceDecimalType) => void;
}

export const useIntlStore = create<IntlState>()(devtools((set, get) => ({
  locale: LanguageCode.DE,
  setLocale: locale => set({ locale }),
  translations: {},
  setTranslations: translations => set({ translations }),
  translate: useTranslateFunction(get),
  formatDate: useFormatDateFunction(values => get().translate(messages.general.ageInYears, values), values => get().translate(messages.general.ageInMonths, values)),
  formatPrice: useFormatPriceFunction(() => get()?.locale, () => get()?.decimalType),
  decimalType: FormatPriceDecimalType.LocalString,
  setDecimalType: decimalType => set({ decimalType }),
})));

export const useIntlStoreSelectors = createSelectors(useIntlStore).use;
export const useTranslate = () => useIntlStoreSelectors.translate();
export const useFormatDate = () => useIntlStoreSelectors.formatDate();
export const useFormatPrice = () => useIntlStoreSelectors.formatPrice();

/**
 * @deprecated
 */
export const useIntl = () => useIntlStore.getState();

export const IntlProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const { locales, deviceLocale } = useEnvStore();

  const locale = useIntlStoreSelectors.locale();

  const setTranslations = useIntlStore(state => state.setTranslations);

  // get initial locale
  const defaultLocale = useMemo(() => first(filter([
    localStorage.getItem('locale'),
    deviceLocale,
    (navigator.languages || [navigator.language]).filter(l => locales.indexOf(l.split('-')[0] as LanguageCode) > -1)[0],
    LanguageCode.DE,
  ])) as LanguageCode, []);

  useEffect(() => {
    // save locale to local storage
    if (locale !== defaultLocale) {
      localStorage.setItem('locale', locale);
    }

    // fetch messages
    axios.get(`/i18n/${locale}.json`, { responseType: 'json' }).then(({ data }) => setTranslations(data));

    // set dayjs locale
    dayjs.locale(locale);

  }, [locale]);

  return <React.Fragment key={locale}>{children}</React.Fragment>;
};
