import React from 'react';
import { useAuthUser } from 'modules/auth/providers';
import { useApi, useIntl } from 'providers';
import { ReportControlContext } from 'modules/reports/index';
import { Container, ModalFormControl } from 'components';
import { faShareSquare } from '@fortawesome/pro-light-svg-icons';
import { Guard } from 'containers';
import { Feature, ReportsSendReportRequest } from 'interfaces/api';
import messages from 'messages';

export const SendReport = (props: ReportControlContext) => {

  const reports = [props.data, ...(props.data.subreports || [])];
  const bid = reports[props.context.selectedReport].bid;
  const patientEmail = reports[props.context.selectedReport].patient?.email;

  const { reports: { sendReport } } = useApi();
  const { translate } = useIntl();
  const user = useAuthUser();

  const labels = messages.reports.controls.sendReport;

  return (
    <ModalFormControl
      icon={faShareSquare}
      label={labels.label}
      notifications={labels.notifications}
      modal={{
        okText: messages.general.send,
        scrollY: true,
      }}
      form={{
        initialValue: { sendToPatient: false } as ReportsSendReportRequest,
        request: value => sendReport({ bid, ...value, email: value.sendToPatient ? undefined : value.email }),
        children: ({ Input, Switch, value }) => (
          <Container padding>
            <Input property={'email'} label={labels.email} disabled={value.sendToPatient}/>
            <Switch property={'sendToPatient'} label={translate(labels.sendToPatient, { email: patientEmail })}/>
            <Input property={'input'} label={labels.message}/>
            <Guard feature={Feature.PdfPassword}>
              <Input property={'pdfPassword'} label={labels.pdfPassword}/>
            </Guard>
            <Switch property={'copyToUser'} label={translate(labels.copyToUser, { email: user?.email })}/>
          </Container>
        ),
      }}
    />
  );

};
