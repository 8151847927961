import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Loader } from '../Loader';
import cx from 'classnames';
import './PullToRefresh.less';
import { faArrowDown } from '@fortawesome/pro-light-svg-icons';
import { Icon } from '../Icon';
import { supportsTouch } from 'utils/dom';
import messages from 'messages';
import { useIntl } from 'providers';
import { useEnv } from 'providers/EnvProvider';

interface PullToRefreshProps {
  isPullable?: boolean;
  onRefresh: () => void;
  children: React.ReactNode;
  setRef?: (args: PullToRefreshRefArgs) => void;
}

export type PullToRefreshRefArgs = {
  onRefreshDone: () => void;
};

export const PullToRefresh = (props: PullToRefreshProps) => {
  const isNative = useEnv.isNative();
  const { translate } = useIntl();
  const { general } = messages;
  const [dragging, setDragging] = useState<boolean>(false);
  const [reloading, setReloading] = useState<boolean>(false);
  const [thresholdBreached, setThresholdBreached] = useState<boolean>(false);
  const [pullDownCaption, setPullDownCaption] = useState<string>(translate(general.pullToRefresh.pullDownCaption));
  const containerRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const pullDownThreshold = 30;
  const maxPullDownDistance = 80;
  const resistance = 3;
  let isReloading = false;
  let pullToRefreshThresholdBreached = false;
  let isDragging = false;
  let startY = 0;
  let currentY = 0;

  const { isPullable, children, onRefresh, setRef } = props;
  const canPullDown = useMemo(() => {
    return isPullable ? isPullable : supportsTouch();
  }, [isPullable]);

  useEffect(() => {
    const childrenEl = childrenRef.current;
    const args = { onRefreshDone };

    setRef?.(args);
    if (canPullDown && childrenRef && childrenRef.current) {
      childrenEl.addEventListener('touchstart', onTouchStart, { passive: true });
      childrenEl.addEventListener('mousedown', onTouchStart);
      childrenEl.addEventListener('touchmove', onTouchMove, { passive: false });
      childrenEl.addEventListener('mousemove', onTouchMove);
      childrenEl.addEventListener('touchend', onEnd);
      childrenEl.addEventListener('mouseup', onEnd);
      document.body.addEventListener('mouseleave', onEnd);
      initContainer();
    }

    return () => {
      if (childrenEl) {
        childrenEl.removeEventListener('touchstart', onTouchStart);
        childrenEl.removeEventListener('mousedown', onTouchStart);
        childrenEl.removeEventListener('touchmove', onTouchMove);
        childrenEl.removeEventListener('mousemove', onTouchMove);
        childrenEl.removeEventListener('touchend', onEnd);
        childrenEl.removeEventListener('mouseup', onEnd);
      }

      document.body.removeEventListener('mouseleave', onEnd);
    };
  }, [
    isPullable,
  ]);

  const initContainer = (): void => {
    requestAnimationFrame(() => {
      if (childrenRef.current) {
        childrenRef.current.style.overflowX = 'hidden';
        childrenRef.current.style.transform = 'unset';
      }
      if (containerRef.current) {
        setDragging(false);
      }

      if (pullToRefreshThresholdBreached) {
        pullToRefreshThresholdBreached = false;
        setThresholdBreached(false);
      }
    });
  };

  const onTouchStart = useCallback((e: MouseEvent | TouchEvent): void => {
    if (isReloading) {
      return;
    }
    isDragging = false;
    setDragging(false);
    if (e instanceof MouseEvent) {
      startY = e.pageY;
    }
    if (window.TouchEvent && e instanceof TouchEvent) {
      startY = e.touches[0].pageY;
    }
    currentY = startY;
    if (e.type === 'touchstart' && isTreeScrollable(e.target as HTMLElement)) {
      return;
    }
    if (childrenRef.current!.getBoundingClientRect().top < 0) {
      return;
    }
    isDragging = true;
  }, []);

  const onTouchMove = useCallback((e: MouseEvent | TouchEvent): void => {
    if (!isDragging || isReloading) {
      return;
    }

    if (window.TouchEvent && e instanceof TouchEvent) {
      currentY = e.touches[0].pageY;
    } else {
      currentY = (e as MouseEvent).pageY;
    }

    setDragging(true);

    if (currentY < startY) {
      isDragging = false;
      setDragging(false);
      return;
    }

    if (e.cancelable) {
      e.preventDefault();
    }

    const yDistanceMoved = Math.min((currentY - startY) / resistance, maxPullDownDistance);

    if (yDistanceMoved >= pullDownThreshold) {
      isDragging = true;
      setDragging(true);
      setPullDownCaption(translate(general.pullToRefresh.releaseCaption));
      pullToRefreshThresholdBreached = true;
      setThresholdBreached(true);
    }

    if (yDistanceMoved >= maxPullDownDistance) {
      return;
    }
    childrenRef.current!.style.overflow = 'visible';
    childrenRef.current!.style.transform = `translate(0px, ${yDistanceMoved}px)`;
  }, []);

  const onEnd = useCallback((): void => {
    isDragging = false;
    setDragging(false);
    startY = 0;
    currentY = 0;

    if (!pullToRefreshThresholdBreached) {
      initContainer();
      return;
    }
    setReloading(true);
    isReloading = true;
    if (childrenRef.current) {
      childrenRef.current.style.overflow = 'hidden';
      childrenRef.current.style.transform = `translate(0px, ${pullDownThreshold}px)`;
    }
    onRefresh();
  }, []);

  const reloadFinished = useCallback((): void => {
    initContainer();
    setReloading(false);
    isReloading = false;
  }, []);

  const isOverflowScrollable = (element: HTMLElement): boolean => {
    const overflowType: string = getComputedStyle(element).overflowY;
    if (element === document.scrollingElement && overflowType === 'visible') {
      return true;
    }

    return !(overflowType !== 'scroll' && overflowType !== 'auto');
  };

  const isScrollable = (element: HTMLElement): boolean => {
    if (!isOverflowScrollable(element)) {
      return false;
    }
    return element.scrollTop > 0;
  };

  const isTreeScrollable = (element: HTMLElement): boolean => {
    if (isScrollable(element)) {
      return true;
    }

    if (element.parentElement == null) {
      return false;
    }

    return isTreeScrollable(element.parentElement);
  };

  const onRefreshDone = (): void => {
    reloadFinished();
  };

  const pullDownText = useMemo(() => {
    return <><span className={'pullDownIcon'}><Icon icon={faArrowDown}/></span>{pullDownCaption}<span className={'pullDownIcon'}><Icon icon={faArrowDown}/></span></>;
  }, [pullDownCaption]);

  return (
    <div className={cx('pullDownToRefreshContainer', { 'no-scroll': !isNative })} ref={containerRef}>
      <div className={cx('pullDownContainer', { dragging }, { reloading }, { thresholdBreached })}>
        {reloading && <Loader/>}
        {dragging && pullDownText}
      </div>
      <div className={cx('pullToRefreshChildren', { 'no-scroll': !isNative })} ref={childrenRef}>
        {children}
      </div>
    </div>
  );
};
