import React, { useMemo } from 'react';
import { GlobalsSendContactFormRequest, Link, LinkPlatform, LinkProduct, Platform, Product } from 'interfaces/api';
import { useAuthUser } from 'modules/auth/providers';
import { useLocation } from 'react-router';
import { filter, map } from 'lodash';
import { useApi, useConfig, useIntl } from 'providers';
import messages from 'messages';
import { Card, Container, Icon, ModalControl, ModalFormControl } from 'components';
import { ApiRequest } from 'containers/ApiRequest';
import { faEnvelope, faFax, faGlobe, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { useEnv } from 'providers/EnvProvider';

export const useLinks = () => {

  const { quickAccess, links } = useConfig();
  const platform = useEnv.platform();
  const { translate, locale } = useIntl();
  const user = useAuthUser();
  const { pathname } = useLocation();
  const { globals: { getTermsAndConditions, getPrivacyStatement, getContactInfo, sendContactForm } } = useApi();

  const overwriteLinks = useMemo((): Link[] => {
    try {
      const parsed = JSON.parse(translate(messages.general.meta.overwriteLinks));
      let xi = 1;
      return map(parsed, (link, index) => ({
        xlinksid: xi++,
        linkhref: link,
        linkname: index,
      }));
    } catch (e) {
      return undefined;
    }
  }, [locale]);

  const entries = useMemo(() => map(overwriteLinks || filter(links?.entries || [], (link) => {

    if (platform === Platform.WEB && link.platform === LinkPlatform.App) {
      return false;
    }

    if (platform !== Platform.WEB && link.platform === LinkPlatform.Web) {
      return false;
    }

    if (link.product === LinkProduct.All && !!user) {
      return true;
    }

    if (link.product === LinkProduct.Login) {
      return !user;
    }

    if (link.product === LinkProduct.ReportQuickAccess) {
      return quickAccess.enabled && pathname === quickAccess.path;
    }

    // @ts-expect-error todo
    return (pathname + '/').indexOf('/' + Product[LinkProduct[link.product]] + '/') === 0;

  }), link => (
    <li key={link.xlinksid}>
      <a href={link.linkhref} target="_blank" rel="noreferrer">
        {link.linkname}
      </a>
    </li>
  )), [pathname, links]);

  const tocLink = useMemo(() => links.hasToc && links.config.showToc
    ? (
      <li key={'toc'}>
        <ModalControl
          label={messages.general.toc}
          modal={{
            title: messages.general.toc,
            footer: null,
            scrollY: true,
            children: (
              <ApiRequest
                request={() => getTermsAndConditions()}
                children={({ data }) => (
                  <Container padding>
                    <div dangerouslySetInnerHTML={{ __html: data.text }}/>
                  </Container>
                )}
              />
            ),
          }}
        />
      </li>
    )
    : undefined, [links]);

  const privacyLink = useMemo(() => links.hasPrivacy && links.config.showPrivacy
    ? (
      <li key={'privacy'}>
        <ModalControl
          label={messages.general.privacy}
          modal={{
            title: messages.general.privacy,
            footer: null,
            scrollY: true,
            children: (
              <ApiRequest
                request={() => getPrivacyStatement()}
                children={({ data }) => (
                  <Container padding>
                    <div dangerouslySetInnerHTML={{ __html: data.text }}/>
                  </Container>
                )}
              />
            ),
          }}
        />
      </li>
    )
    : undefined, [links]);

  const contactLink = useMemo(() => links.config.showContact
    ? (
      <li key={'contact'}>
        <ModalControl
          label={messages.general.contact}
          modal={{
            title: messages.general.contact,
            footer: null,
            scrollY: true,
            narrow: true,
            children: (
              <ApiRequest
                request={() => getContactInfo()}
                children={({ data }) => (
                  <Container padding>
                    {data.map(info => (
                      <Card key={info.title} title={filter([info.title, info.short]).join(': ')} className={'info-popover contact-info'}>
                        {filter([
                          info.address && (
                            <span>{info.address}</span>
                          ),
                          info.city && (
                            <span>{info.city}</span>
                          ),
                          info.phone && (
                            <span>
                              <Icon icon={faPhone}/>
                              {info.phone}
                            </span>
                          ),
                          info.fax && (
                            <span>
                              <Icon icon={faFax}/>
                              {info.fax}
                            </span>
                          ),
                          info.email && (
                            <a href={'mailto:' + info.email}>
                              <Icon icon={faEnvelope}/>
                              {info.email}
                            </a>
                          ),
                          info.www && (
                            <a href={info.www} target={'_blank'} rel="noreferrer">
                              <Icon icon={faGlobe}/>
                              {info.www}
                            </a>
                          ),
                        ])}
                      </Card>
                    ))}
                  </Container>
                )}
              />
            ),
          }}
        />
      </li>
    )
    : undefined, [links]);

  const contactFormLink = useMemo(() => (user && links.config.showContactForm)
    ? (
      <li key={'contact'}>
        <ModalFormControl
          label={messages.general.contactForm.label}
          notifications={messages.general.contactForm.notifications}
          modal={{
            okText: messages.general.send,
            title: messages.general.contactForm.label,
            scrollY: true,
            narrow: true,
          }}
          form={{
            initialValue: {} as GlobalsSendContactFormRequest,
            request: value => sendContactForm(value),
            children: ({ Input, TextArea, Switch }) => (
              <Container padding>
                <Input property={'subject'} label={messages.general.contactForm.subject}/>
                <TextArea property={'message'} label={messages.general.contactForm.message}/>
              </Container>
            ),
          }}
        />
      </li>
    )
    : undefined, [links]);

  return useMemo(() => {
    return filter([tocLink, privacyLink, contactLink, contactFormLink, ...entries]);
  }, [entries, tocLink, privacyLink, contactLink]);

};
