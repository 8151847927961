import { useCallback, useMemo } from 'react';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { useApiStoreSelectors } from 'providers/ApiProvider/api.store.ts';
import { useIntlStoreSelectors } from 'providers/IntlProvider';
import { getClient } from './client';
import { ApiDefinition } from 'interfaces/api';
import { useLogger } from 'providers/LoggerProvider';
import { useEnv } from 'providers/EnvProvider';

export const useApiClient = (lid?: number): ApiDefinition => {

  const locale = useIntlStoreSelectors.locale();

  const isNative = useEnv.isNative();

  const endpoint = useEnv.BACKEND_URL();
  const target = useEnv.TARGET();
  const buildNumber = useEnv.BUILD_NUMBER();

  const user = useAuthStoreSelectors.user();
  const logout = useAuthStoreSelectors.logout();

  const setUpdateAvailable = useApiStoreSelectors.setUpdateAvailable();

  const logger = useLogger('api');

  const onUnauthorizedError = useCallback(() => {
    if (user) {
      logout();
    }
  }, [user]);

  const checkResponseHeaders = useCallback((headers: any) => {
    if (headers['x-build'] !== buildNumber && !isNative) {
      setUpdateAvailable(true);
    }
  }, [endpoint]);

  return useMemo(() => getClient({
    lid,
    locale,
    logger,
    endpoint,
    target,
    checkResponseHeaders,
    onUnauthorizedError,
    version: APP_VERSION,
  }), [lid, locale, logger, endpoint]);
};
