import { useCallback, useMemo, useState } from 'react';
import { OrderRequirementsFilter, RequirementAggregationType } from 'interfaces/api';
import { toggleArrayItem } from 'utils/helpers';
import { ListColumnType } from 'components';
import { useOfficeDoctorContext } from 'modules/orders/providers';
import { useIntl } from 'providers';
import messages from 'messages';
import { useGetFilterConfig } from '../utils';
import { filter, find } from 'lodash';
import { OrderAggregations } from 'modules/orders/constants';

export type ParametersContext = {
  filters?: OrderRequirementsFilter[];
  groupBy?: RequirementAggregationType;
  pinned?: boolean;
  top?: boolean;
  bak?: boolean;
  query?: string;
  groupFormId?: number;
  pool?: boolean;
};

export const useParameters = () => {

  const { translate } = useIntl();
  const getFilterConfig = useGetFilterConfig();

  const { wizardSettings } = useOfficeDoctorContext();

  const [params, setParams] = useState<ParametersContext>({ filters: [] });

  const reset = useCallback(() => setParams({ filters: [], pool: false }), []);

  const isSpecialView = useCallback((groupBy: RequirementAggregationType) => [
    undefined,
    RequirementAggregationType.ProfilesOnly,
    RequirementAggregationType.GroupFormCategory,
  ].includes(groupBy), []);

  const toggleFilter = useCallback((filter: OrderRequirementsFilter, otherParams?: Partial<typeof params>) => {
    const filters = toggleArrayItem(params.filters, filter, f => f.id);
    const groupBy = filter.type === params.groupBy || isSpecialView(params.groupBy) ? RequirementAggregationType.Name : params.groupBy;
    setParams({ ...params, filters, groupBy, ...otherParams });
  }, [params, isSpecialView]);

  const togglePinned = useCallback(() => setParams({
    ...params,
    pinned: params.pinned ? undefined : true,
    groupBy: isSpecialView(params.groupBy) ? RequirementAggregationType.Name : params.groupBy,
    groupFormId: undefined,
  }), [params, isSpecialView]);

  const toggleTop = useCallback(() => setParams({
    ...params,
    top: params.top ? undefined : true,
    groupBy: isSpecialView(params.groupBy) ? RequirementAggregationType.Name : params.groupBy,
    groupFormId: undefined,
  }), [params, isSpecialView]);

  const toggleBak = useCallback(() => setParams({
    ...params,
    bak: params.bak ? undefined : true,
    groupBy: isSpecialView(params.groupBy) ? RequirementAggregationType.Materials : params.groupBy,
    groupFormId: undefined,
  }), [params, isSpecialView]);

  const setGroupBy = useCallback((groupBy: RequirementAggregationType) => setParams({
    ...params,
    groupBy,
    groupFormId: undefined,
    filters: isSpecialView(groupBy) ? [] : params.filters,
    pinned: isSpecialView(groupBy) ? undefined : params.pinned,
    top: isSpecialView(groupBy) ? undefined : params.top,
    bak: isSpecialView(groupBy) ? undefined : params.bak,
  }), [params, isSpecialView]);

  const setGroupForm = useCallback((groupFormId: number) => setParams({
    groupFormId,
    groupBy: RequirementAggregationType.GroupFormCategory,
    filters: [],
    pinned: undefined,
    top: undefined,
    bak: undefined,
    pool: params.pool,
  }), [params]);

  const setFilter = useCallback((filter: OrderRequirementsFilter) => setParams({
    ...params,
    filters: [filter],
    groupBy: RequirementAggregationType.Name,
    groupFormId: undefined,
    pinned: undefined,
    top: undefined,
    bak: undefined,
  }), [params]);

  const setQuery = useCallback((value: string) => setParams({
    ...params,
    query: value && value.length > 0 ? value : undefined,
    groupBy: isSpecialView(params.groupBy) ? RequirementAggregationType.Name : params.groupBy,
  }), [params, isSpecialView]);

  const setPool = useCallback((value: boolean) => setParams({
    ...params,
    pool: value,
    groupFormId: value ? undefined : params.groupFormId,
    groupBy: (value && params.groupBy === RequirementAggregationType.GroupFormCategory) ? RequirementAggregationType.Materials : params.groupBy,
  }), [params]);

  const getHeader = useCallback(() => {
    if (!isSpecialView(params.groupBy)) {
      return translate(messages.orders.requirementsAggregated, { GROUP: translate(messages.orders.requirementAggregations.types[params.groupBy]) });
    }
    return translate(messages.orders.requirements);
  }, [isSpecialView, translate, params.groupBy]);

  const filtersIncludesName = useMemo(() => params.filters.map(f => f.type).includes(RequirementAggregationType.Name), [params.filters]);

  const isShowAll = useMemo(
    () => params.groupBy === RequirementAggregationType.Name && filtersIncludesName,
    [params.groupBy, filtersIncludesName],
  );

  const getColumnType = useCallback((broader?: boolean) => {
    if (params.groupBy === undefined) {
      // start screen: horizontal
      return broader ? ListColumnType.HorizontalBroader : ListColumnType.Horizontal;
    } else if (params.groupBy === RequirementAggregationType.ProfilesOnly) {
      // special screen profiles: vertical
      if (wizardSettings.preferences.orderWizardDisableMasonryView) {
        return broader ? ListColumnType.HorizontalBroader : ListColumnType.Horizontal;
      }
      return broader ? ListColumnType.VerticalBroader : ListColumnType.Vertical;
    } else if (params.groupBy !== RequirementAggregationType.Name) {
      // aggregation (not name): masonry
      if (wizardSettings.preferences.orderWizardDisableMasonryView) {
        return broader ? ListColumnType.HorizontalBroader : ListColumnType.Horizontal;
      }
      return broader ? ListColumnType.MasonryBroader : ListColumnType.Masonry;
    } else if (isShowAll) {
      // name is filtered (show all): horizontal
      return ListColumnType.HorizontalBroader;
    } else {
      // alphabetical aggregation
      if (wizardSettings.preferences.orderWizardDisableMasonryView) {
        return broader ? ListColumnType.HorizontalBroader : ListColumnType.Horizontal;
      }
      return broader ? ListColumnType.VerticalBroader : ListColumnType.Vertical;
    }
  }, [params.groupBy, params.filters, isShowAll, wizardSettings]);

  const getColumns = useCallback(() => {
    return params.groupFormId ? find(wizardSettings.groupForms, { id: params.groupFormId }).columns : undefined;
  }, [params.groupFormId, wizardSettings]);

  const aggregations = useMemo(() => filter(OrderAggregations, ag => !params.pool || ag.type !== RequirementAggregationType.OrderForm), [params.pool]);

  return {
    params,
    reset,
    toggleFilter,
    setFilter,
    setGroupBy,
    togglePinned,
    toggleTop,
    toggleBak,
    setQuery,
    setPool,
    getHeader,
    isShowAll,
    getColumnType,
    setGroupForm,
    getColumns,
    aggregations,
    getFilterConfig: (filter: OrderRequirementsFilter) => getFilterConfig(filter, wizardSettings),
  };
};
