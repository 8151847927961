import React from 'react';
import { Container, ContainerProps } from 'components';
import { SwipeableProps, useSwipeable } from 'react-swipeable';

export const SwipeableContainer: React.FC<ContainerProps & { swipeableConfig: SwipeableProps }> = (props) => {

  const handlers = useSwipeable(props.swipeableConfig);

  return (
    <Container
      {...props}
      {...handlers}
    />
  );

};
