import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { default as AntInput, InputProps as AntInputProps } from 'antd/es/input';
import 'react-phone-input-2/lib/style.css';
import './Input.less';
import { InputRef } from 'antd';
import { Message } from 'interfaces';
import { useIntlStoreSelectors } from 'providers';
import PhoneInput from 'react-phone-input-2';
import phoneInputLocaleDe from 'react-phone-input-2/lang/de.json';
import phoneInputLocaleIt from 'react-phone-input-2/lang/it.json';
import phoneInputLocaleFr from 'react-phone-input-2/lang/fr.json';
import phoneInputLocaleHu from 'react-phone-input-2/lang/hu.json';
import { LanguageCode } from 'interfaces/api';

const phoneInputLocaleFiles: Record<LanguageCode, object | undefined> = {
  [LanguageCode.DE]: phoneInputLocaleDe,
  [LanguageCode.EN]: undefined,
  [LanguageCode.IT]: phoneInputLocaleIt,
  [LanguageCode.FR]: phoneInputLocaleFr,
  [LanguageCode.HU]: phoneInputLocaleHu,
  [LanguageCode.NL]: undefined,
};

export enum InputFormat {
  Numeric,
  Alphabetic,
  Alphanumeric,
}

type OverwriteProps = {
  format?: InputFormat | string;
  placeholder?: Message;
};

export type InputProps = Omit<AntInputProps, keyof OverwriteProps> & OverwriteProps;

export const Input = forwardRef<InputRef, InputProps>((props, ref) => {

  const { format, placeholder, ...rest } = props;

  const inputRef = useRef<InputRef>(null);

  useImperativeHandle(ref, () => {
    if (props.autoFocus) {
      window.setTimeout(() => inputRef.current?.focus?.(), 250);
    }
    return inputRef.current;
  }, []);

  const locale = useIntlStoreSelectors.locale();
  const translate = useIntlStoreSelectors.translate();

  if (props.inputMode === 'tel') {
    return (
      <PhoneInput
        country={'de'}
        copyNumbersOnly={false}
        preferredCountries={['de', 'at', 'ch']}
        value={props.value as string}
        onChange={value => props.onChange({ target: { value: value ? '+' + value : value } } as React.ChangeEvent<HTMLInputElement>)}
        disabled={props.disabled}
        inputClass={'ant-input'}
        placeholder={'+49 1231 123123'}
        regions={'europe'}
        localization={phoneInputLocaleFiles[locale]}
        countryCodeEditable={false}
      />
    );
  }

  return (
    <AntInput
      ref={inputRef}
      placeholder={translate(placeholder)}
      {...rest}
    />
  );
});
